import Vue from 'vue'
import App from './App.vue'
import ApiService from './services/api.service.js'
import router from './router'
import store from './store'
import VueGoodTablePlugin from 'vue-good-table'
import VuePaginateAl from 'vue-paginate-al'
import Meta from 'vue-meta'
import { outcomeGrades, formatGrades, formatTheme6Grades, getGradingColor } from './_helpers/grading'

// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import './assets/styles/app.scss'

const api = new ApiService()
store.$api = api

// MF This doesn't look like its used?
// const hbConfig = {
//   apiKey: 'hbp_J3rd6ay78gFdNAKbPK6E0gM5aYEZwR1Jx0B4',
//   environment: process.env.VUE_APP_HB_ENV || 'development',
//   revision: process.env.VUE_APP_COMMIT || undefined,
//   projectRoot: 'webpack:///./',
//   breadcrumbsEnabled: true,
//   enableUncaught: true,
//   enableUnhandledRejection: true,
//   debug: !process.env.VUE_APP_HB_ENV
// }

Vue.prototype.ApiService = api
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(VueGoodTablePlugin)
Vue.component('vue-paginate-al', VuePaginateAl)

// filter doesn't work unless you iterate here: WAT
Vue.filter('formatOutcome', val => {
  const grades = outcomeGrades

  let colour = 'Bronze'
  Object.keys(grades).forEach(grade => {
    colour = grades[grade].min <= val && val <= grades[grade].max
      ? grade
      : colour
  })
  return colour
})

// filter doesn't work unless you iterate here: WAT
Vue.filter('formatGrade', val => {
  const { outcome, theme } = val
  const grades = theme === 6 ? formatTheme6Grades : formatGrades

  let colour = 'Bronze'
  Object.keys(grades).forEach(grade => {
    colour = grades[grade].min <= outcome && outcome <= grades[grade].max
      ? grade
      : colour
  })

  return colour
})

// Vue.filter('formatOutcome', val => {
//   const color = getGradingColor(val, outcomeGrades, 'Bronze')

//   return color
// })

// Vue.filter('formatGrade', (val) => {
//   console.log(val)
//   console.log(color)

//   const color = getGradingColor(val, formatGrades, 'Bronze')
  

//   return color
// })

// add meta 
// for the purpose of header tags
Vue.use(Meta)

new Vue({ router, store, render: h => h(App) }).$mount('#app')
