// flip so positive at top, neg at bottom

const ratingScale = [
  {
    label: "Strongly Agree",
    value: 5,
  },
  {
    label: "Agree",
    value: 4,
  },
  {
    label: "Unsure",
    value: 3,
  },
  {
    label: "Disagree",
    value: 2,
  },
  {
    label: "Strongly Disagree",
    value: 1,
  },
];

const ratingScaleAlternate = [
  {
    label: "Really agree",
    emoji: "😀",
    value: 5,
  },
  {
    label: "Mostly agree",
    emoji: "🙂",
    value: 4,
  },
  {
    label: "Don't know",
    emoji: "😐",
    value: 3,
  },
  {
    label: "Don't really agree",
    emoji: "😕",
    value: 2,
  },
  {
    label: "Don't agree at all",
    emoji: "🙁",
    value: 1,
  },
];

module.exports = {
  surveys: [
    {
      title: "C/YP Feedback - Older Children",
      type: "child_feedback",
      questions: [
        {
          index: 0,
          text: "I was aware my annual review meeting was taking place",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 1,
          text:
            "I had the option to be part of the meeting or to share my view before the meeting",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 2,
          text: "My views and feelings were seen as important",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 3,
          text:
            "Someone spoke to me to help me understand what was discussed in the meeting and let me know if any changes need to be made to my EHCP",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 4,
          text: "I feel the annual meeting was positive",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 5,
          text:
            "How do you currently receive information about help and support for special education needs",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 6,
          text: "Where do you go for general news and information?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 7,
          text:
            "What would you like to find out more about in terms of the services you receive?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 8,
          text:
            "How would you like to receive information through on the services you receive?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 9,
          text: "Any other comments (optional)",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "Please provide any other comments",
            accepts: {
              type: "text",
            },
          },
        },
      ],
    },
    {
      title: "C/YP Feedback - Younger Children",
      type: "child_feedback_2",
      questions: [
        {
          index: 0,
          text:
            "I know there was a meeting with my parents and other adults who help me",
          type: "rating_scale",
          ratingScale: [...ratingScaleAlternate],
        },
        {
          index: 1,
          text: `
            I got to share things about me:\n
                \t• What I like and don’t like and\n
                \t• What I am good at and things I think I’m not so good at
            `,
          type: "rating_scale",
          ratingScale: [...ratingScaleAlternate],
        },
        {
          index: 2,
          text:
            "Someone talked to me after the meeting to tell me what happened and how people will help me",
          type: "rating_scale",
          ratingScale: [...ratingScaleAlternate],
        },
        {
          index: 3,
          text:
            "How do you currently receive information about help and support for special education needs",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 4,
          text: "Where do you go for general news and information?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 5,
          text:
            "What would you like to find out more about in terms of the services you receive?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 6,
          text:
            "How would you like to receive information through on the services you receive?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 7,
          text: "Any other comments (optional)",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "Please provide any other comments",
            accepts: {
              type: "text",
            },
          },
        },
      ],
    },
    {
      title: "Parent/Carer Feedback",
      type: "carer_feedback",
      questions: [
        {
          index: 0,
          text:
            "I was informed in advance the annual review meeting was taking place and was asked for my views",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 1,
          text:
            "I had been informed of the purpose of the annual review meeting and the process",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 2,
          text:
            "My views and feelings were seen as important in this process and I was fully included in the meeting",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 3,
          text: "The Annual Review meeting had clear outcomes / next steps",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 4,
          text: "I feel the annual meeting was positive",
          type: "rating_scale",
          ratingScale: [...ratingScale],
        },
        {
          index: 5,
          text: "Any other comments (optional)",
          optional: true,
          type: "input",
          input: {
            defaultValue: "",
            placeholder: "Please provide any other comments",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 6,
          secondPage:true,
          text:
            "How do you currently receive information about help and support for special education needs",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 7,
          secondPage:true,
          text: "Where do you go for general news and information?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 8,
          secondPage:true,
          text:
            "What would you like to find out more about in terms of the services you receive?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
        {
          index: 9,
          secondPage:true,
          text:
            "How would you like to receive information through on the services you receive?",
          type: "input",
          optional: true,
          input: {
            defaultValue: "",
            placeholder: "",
            accepts: {
              type: "text",
            },
          },
        },
      ],
    },
  ],
};
