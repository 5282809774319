const answers = {
  full_criteria: [
    {
      value: 3,
      title: 'Clear that criteria met'
    },
    {
      value: 2,
      title: 'Criteria partially met'
    },
    {
      value: 1,
      title: 'Criteria not met'
    },
    {
      value: 0,
      title: 'Unknown'
    },
    {
      value: -1,
      title: 'Not applicable'
    }
  ],
  boolean: [
    {
      value: true,
      title: 'Yes',
    },
    {
      value: false,
      title: 'No',
    }
  ],
  needs: [
    {
      value: 1,
      title: 'C&L'
    },
    {
      value: 2,
      title: 'SEMH'
    },
    {
      value: 3,
      title: 'Sensory / Physical'
    },
    {
      value: 4,
      title: 'C&I'
    },
    {
      value: 5,
      title: 'Higher education and/or employment'
    },
    {
      value: 6,
      title: 'Independent living'
    },
    {
      value: 7,
      title: 'Participating in society'
    },
    {
      value: 8,
      title: 'Maintaining good health in adult life'
    },
  ],
  target: [
    {
      value: 4,
      title: 'Partially achieved target'
    },
    {
      value: 3,
      title: 'Achieved target'
    },
    {
      value: 2,
      title: 'Exceeded target'
    },
    {
      value: 1,
      title: 'Not achieved target'
    },
    {
      value: 0,
      title: 'Unknown'
    }
  ],
  outcome: [
    {
      value: 0,
      title: 'Unknown'
    },
    {
      value: 1,
      title: 'On track to fully achieve'
    },
    {
      value: 2,
      title: 'On track to partially achieve'
    },
    {
      value: 3,
      title: 'Not on track to achieve'
    },
    {
      value: 4,
      title: 'Likely to exceed'
    }
  ],
  boolean_with_partially: [
    {
      value: 3,
      title: 'Yes'
    },
    {
      value: 2,
      title: 'Partially'
    },
    {
      value: 1,
      title: 'No'
    },
  ]
}

module.exports = {
  themes: [
    {
      theme: 1,
      title: 'Timeliness and quality of identification',
      description: '',
      questions: [
        {
          text: 'School/setting has provided advice, and where appropriate sought advice from other education professionals',
          answers: [...answers.full_criteria]
        },
        {
          text: 'School / Setting sought advice on the C/YP from health ',
          answers: [...answers.full_criteria]
        },
        {
          text: 'School / Setting sought advice on the C/YP from social care',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Advice received was up to date and assessed progress to date, and the impact of the interventions over the past year from Education',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Advice received was up to date and assessed progress to date, and the impact of the interventions over the past year from health',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Advice received was up to date and assessed progress to date, and the impact of the interventions over the past year from social care',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The school notified parents / carers at least 2 weeks before the review meeting date',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The school notified relevant professionals at least 2 weeks before the review meeting date',
          answers: [...answers.full_criteria]
        },
        {
          text: 'All relevant people for that child were involved in the review (i.e. attended or sent their views in)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The annual review took place within 12 months of the C/YP\'s last annual review (6 months for C/YP under 5) or within 12 months of the final EHCP being issued (for new EHCPs)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Following the review, the report was received by the LA and logged on relevant system within 2 weeks of the review meeting',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The LA made a decision whether to cease, maintain or amend on the annual review paperwork within 4 weeks of the annual review meeting',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The decision was communicated to parents / carers',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If the paperwork was recieved by the LA within a 2 week timeframe, the LA made a decision within 8 weeks of the AR meeting occurring',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If applicable, there is evidence that the reviewed plan was finialised and returned to parents / carers',
          answers: [...answers.full_criteria]
        },
      ]
    },
    {
      theme: 2,
      title: 'Meeting needs',
      description: '',
      questions: [
        {
          text: 'Basic details are complete and correct',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Parents/carers notified of the opportunity to access SENDIASS services / advocacy services at least 2 weeks before the review date',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The CYP was involved in the review process',
          answers: [...answers.full_criteria]
        },
        {
          text: 'It is clear how accommodations were made to elicit the C/YP\'s views (either during or prior to the review)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'It appears the C/YP\'s views have had an impact in the annual review meeting (e.g. Steps towards long term outcomes, any potential changes)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The CYP\'s parents / carers were involved in assessing and reviewing the CYP\'s progress towards their outcomes',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where relevant, emphasis in the review has been given to a phase transfer',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where relevant, emphasis in the review has been given to preparation for adulthood',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The review indicates that information on the CYP\'s progress during the previous year was considered',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, the review has indicated where changes are required to ensure the plan accurately describes the C/YP\'s needs',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, the review has indicated where changes are required to ensure the provision in the plan effectively meets the C/YP\'s needs',
          answers: [...answers.full_criteria]
        }
      ]
    },
    {
      theme: 3,
      title: 'Outcomes',
      description: '',
      questions: [
        {
          text: 'Progress across all outcomes included in EHCP has been considered(e.g.academic, emotional well - being, health & care)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where relevant and appropriate, relevant progress and/or assessment data, has been used to set targets and evaluate outcomes',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where relevant, the impact of educational interventions has been reviewed',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where relevant, the impact of health interventions has been reviewed',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where relevant, the impact of social care interventions has been reviewed',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The review clearly describes progress in relation to the outcomes included in the EHCP',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The review sets new short term targets or steps for the coming year, if appropriate',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The review recommends new outcomes, or adjustments to existing outcomes, to be achieved at the end of the next key stage',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If new outcomes are recommended, these are SMART (specific, measurable, achievable, relevant and time bound)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If the review takes place when the C/YP is in Year 9 or above, either the existing ST (Short term) or  LT (Long term) outcomes, or new suggested ST or LT outcomes focus on Preparing for Adulthood (PfA)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, targets and care needs relating to phase transfer have been set / reviewed',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, for phase transfer, the destination school aligns with the C/YP\'s aspirations and achievements',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, when leaving education, the destination for adulthood aligns with the C/YP\'s aspirations and achievements',
          answers: [...answers.full_criteria]
        },
      ]
    },
    {
      theme: 4,
      title: 'Provision',
      description: '',
      questions: [
        {
          text: 'The review indicates what changes might need to be made to the educational support that is provided? E.g. if provision is no longer appropriate or needs adjusting.',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The review indicates what changes might need to be made to the health support that is provided? E.g. if provision is no longer appropriate or needs adjusting.',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The review indicates what changes might need to be made to thesocial care support that is provided? E.g. if provision is no longer appropriate or needs adjusting.',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If changes are recommended, is this quantified and specified e.g. details of who will deliver provision, or how often / how long?',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If the review takes place when the C/YP is in Year 9 or above, either the existing or suggested short term or long term outcomes focus on Preparing for Adulthood (PfA)',
          answers: [...answers.full_criteria]
        },
        {
          text: 'If additional provision is recommended, it is clear which professional has recommended this',
          answers: [...answers.full_criteria]
        }
      ]
    },
    {
      theme: 5,
      title: 'Post-annual review',
      description: '',
      questions: [
        {
          text: 'The annual review has been logged and recorded on the LA system',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, the LA have updated the plan reflecting what was agreed at the annual review meeting',
          answers: [...answers.full_criteria]
        },
        {
          text: 'Where applicable, the LA have ceased / intends to cease the plan at the end of the academic year, reflecting what was discussed at the Annual Review meeting',
          answers: [...answers.full_criteria]
        },
        {
          text: 'The parent / carer and CYP were asked for their feedback on the review process and outcomes',
          answers: [...answers.full_criteria]
        }
      ]
    },
    {
      theme: 6,
      title: 'Outcomes (Long Term) monitoring',
      description: 'For each long term outcome on the CYP\'s annual review',
      questions: [
        {
          text: 'Is there evidence the long term outcomes within the C/YP\'s EHCP were reviewed?',
          answers: [...answers.boolean],
          type: 'boolean',
          questionIndex: 0,
        },
        {
          text: 'Are the outcomes appropriately SMART?',
          answers: [...answers.boolean_with_partially],
          questionIndex: 6,
        },
        {
          text: 'For each long term outcome is there evidence of progress made? Is it clear the level of progress made towards each long term outcome?',
          answers: [...answers.boolean_with_partially],
          questionIndex: 7,
        },
        {
          text: 'How many long term outcomes were reviewed?',
          answers: [...Array(11).keys()].map(answer => {
            return {
              value: answer,
              title: answer
            }
          }),
          questionIndex: 1,
        },
        {
          text: 'What broad area of need does the target align to',
          answers: [...answers.needs],
          questionIndex: 2,
        },
        {
          text: 'Has the child reached the expected end point (timeframe - e.g. end of key stage) of the outcome:',
          answers: [...answers.boolean],
          type: 'boolean',
          questionIndex: 3,
        },
        {
          text: 'Has the child achieved the outcome:',
          answers: [...answers.target],
          questionIndex: 4,
        },
        {
          text: 'Is the child on track to achieve the outcome:',
          answers: [...answers.outcome],
          questionIndex: 5,
        }
      ]
    }
  ]
}
